var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "profile-page" }, [
    _vm._m(0),
    _c("section", { staticClass: "section section-skew" }, [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c(
            "card",
            {
              staticClass: "card-profile",
              attrs: { shadow: "", "no-body": "" },
            },
            [
              _c("div", { staticClass: "px-4" }, [
                _c("div", { staticClass: "row justify-content-start" }, [
                  _c("div", { staticClass: "col-lg-6 order-lg-1" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "card-profile-stats card-profile-stats justify-content-start",
                      },
                      [
                        _c("div", [
                          _c("span", { staticClass: "description" }, [
                            _vm._v("Estado Selecionado"),
                          ]),
                          _c("span", { staticClass: "heading" }, [
                            _vm._v(_vm._s(_vm.selectedUfState)),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "col-lg-6 order-lg-3" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "card-profile-stats d-flex justify-content-center",
                      },
                      [
                        _c("div", [
                          _c("span", { staticClass: "heading" }, [
                            _vm._v(_vm._s(_vm.stats.notices)),
                          ]),
                          _c("span", { staticClass: "description" }, [
                            _vm._v("Editais"),
                          ]),
                        ]),
                        _c("div", [
                          _c("span", { staticClass: "heading" }, [
                            _vm._v(_vm._s(_vm.stats.laws)),
                          ]),
                          _c("span", { staticClass: "description" }, [
                            _vm._v("Leis"),
                          ]),
                        ]),
                        _c("div", [
                          _c("span", { staticClass: "heading" }, [
                            _vm._v(_vm._s(_vm.stats.publication)),
                          ]),
                          _c("span", { staticClass: "description" }, [
                            _vm._v("Publicações"),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "text-center" }, [
                  _c("div", { staticClass: "container ct-example-row" }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-sm" },
                        [
                          _c(
                            "base-radio",
                            {
                              staticClass: "mb-3",
                              attrs: { name: "0" },
                              model: {
                                value: _vm.radioState,
                                callback: function ($$v) {
                                  _vm.radioState = $$v
                                },
                                expression: "radioState",
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://demos.creative-tim.com/argon-design-system/assets/img/icons/flags/BR.png",
                                },
                              }),
                              _vm._v("\n                    BR"),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-sm" },
                        [
                          _c(
                            "base-radio",
                            {
                              staticClass: "mb-3",
                              attrs: { name: "6" },
                              model: {
                                value: _vm.radioState,
                                callback: function ($$v) {
                                  _vm.radioState = $$v
                                },
                                expression: "radioState",
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("../../public/img/icons/flags/al.png"),
                                  width: "23px",
                                  height: "17px",
                                },
                              }),
                              _vm._v("\n                    AL"),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-sm" },
                        [
                          _c(
                            "base-radio",
                            {
                              staticClass: "mb-3",
                              attrs: { name: "7" },
                              model: {
                                value: _vm.radioState,
                                callback: function ($$v) {
                                  _vm.radioState = $$v
                                },
                                expression: "radioState",
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("../../public/img/icons/flags/ba.png"),
                                  width: "23px",
                                  height: "17px",
                                },
                              }),
                              _vm._v("\n                    BA"),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-sm" },
                        [
                          _c(
                            "base-radio",
                            {
                              staticClass: "mb-3",
                              attrs: { name: "2" },
                              model: {
                                value: _vm.radioState,
                                callback: function ($$v) {
                                  _vm.radioState = $$v
                                },
                                expression: "radioState",
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("../../public/img/icons/flags/ce.png"),
                                  width: "23px",
                                  height: "17px",
                                },
                              }),
                              _vm._v("\n                    CE"),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-sm" },
                        [
                          _c(
                            "base-radio",
                            {
                              staticClass: "mb-3",
                              attrs: { name: "1" },
                              model: {
                                value: _vm.radioState,
                                callback: function ($$v) {
                                  _vm.radioState = $$v
                                },
                                expression: "radioState",
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("../../public/img/icons/flags/ma.png"),
                                  width: "23px",
                                  height: "17px",
                                },
                              }),
                              _vm._v("\n                    MA"),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-sm" },
                        [
                          _c(
                            "base-radio",
                            {
                              staticClass: "mb-3",
                              attrs: { name: "8" },
                              model: {
                                value: _vm.radioState,
                                callback: function ($$v) {
                                  _vm.radioState = $$v
                                },
                                expression: "radioState",
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("../../public/img/icons/flags/pb.png"),
                                  width: "23px",
                                  height: "17px",
                                },
                              }),
                              _vm._v("\n                    PB"),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-sm" },
                        [
                          _c(
                            "base-radio",
                            {
                              staticClass: "mb-3",
                              attrs: { name: "5" },
                              model: {
                                value: _vm.radioState,
                                callback: function ($$v) {
                                  _vm.radioState = $$v
                                },
                                expression: "radioState",
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("../../public/img/icons/flags/pe.png"),
                                  width: "23px",
                                  height: "17px",
                                },
                              }),
                              _vm._v("\n                    PE"),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-sm" },
                        [
                          _c(
                            "base-radio",
                            {
                              staticClass: "mb-3",
                              attrs: { name: "3" },
                              model: {
                                value: _vm.radioState,
                                callback: function ($$v) {
                                  _vm.radioState = $$v
                                },
                                expression: "radioState",
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("../../public/img/icons/flags/pi.png"),
                                  width: "23px",
                                  height: "17px",
                                },
                              }),
                              _vm._v("\n                    PI"),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-sm" },
                        [
                          _c(
                            "base-radio",
                            {
                              staticClass: "mb-3",
                              attrs: { name: "4" },
                              model: {
                                value: _vm.radioState,
                                callback: function ($$v) {
                                  _vm.radioState = $$v
                                },
                                expression: "radioState",
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("../../public/img/icons/flags/rn.png"),
                                  width: "23px",
                                  height: "17px",
                                },
                              }),
                              _vm._v("\n                    RN"),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-sm" },
                        [
                          _c(
                            "base-radio",
                            {
                              staticClass: "mb-3",
                              attrs: { name: "9" },
                              model: {
                                value: _vm.radioState,
                                callback: function ($$v) {
                                  _vm.radioState = $$v
                                },
                                expression: "radioState",
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("../../public/img/icons/flags/se.png"),
                                  width: "23px",
                                  height: "17px",
                                },
                              }),
                              _vm._v("\n                    SE"),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "text-left" }, [
                  _c("div", { staticClass: "container ct-example-row" }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-sm" },
                        [
                          _c(
                            "base-radio",
                            {
                              staticClass: "mb-3",
                              attrs: { name: "0" },
                              model: {
                                value: _vm.radioPublicationType,
                                callback: function ($$v) {
                                  _vm.radioPublicationType = $$v
                                },
                                expression: "radioPublicationType",
                              },
                            },
                            [_vm._v("\n                    Todos")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-sm" },
                        [
                          _c(
                            "base-radio",
                            {
                              staticClass: "mb-3",
                              attrs: { name: "1" },
                              model: {
                                value: _vm.radioPublicationType,
                                callback: function ($$v) {
                                  _vm.radioPublicationType = $$v
                                },
                                expression: "radioPublicationType",
                              },
                            },
                            [_vm._v("\n                    Editais")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-sm" },
                        [
                          _c(
                            "base-radio",
                            {
                              staticClass: "mb-3",
                              attrs: { name: "2" },
                              model: {
                                value: _vm.radioPublicationType,
                                callback: function ($$v) {
                                  _vm.radioPublicationType = $$v
                                },
                                expression: "radioPublicationType",
                              },
                            },
                            [_vm._v("\n                    Legislação")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-sm" },
                        [
                          _c(
                            "base-radio",
                            {
                              staticClass: "mb-3",
                              attrs: { name: "3" },
                              model: {
                                value: _vm.radioPublicationType,
                                callback: function ($$v) {
                                  _vm.radioPublicationType = $$v
                                },
                                expression: "radioPublicationType",
                              },
                            },
                            [_vm._v("\n                    Publicação")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
                _vm.loading
                  ? _c("div", { staticClass: "loading" }, [_c("loadpage")], 1)
                  : _vm._e(),
                _vm.error
                  ? _c("div", { staticClass: "error" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.errorMessage) +
                          "\n          "
                      ),
                    ])
                  : _vm._e(),
                _vm.post
                  ? _c("div", { staticClass: "py-5 text-center" }, [
                      _c(
                        "div",
                        [
                          _c("b-table", {
                            staticClass: "tableData",
                            attrs: {
                              striped: "",
                              hover: "",
                              responsive: "",
                              fields: _vm.fields,
                              items: _vm.results,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "cell(title)",
                                  fn: function (results) {
                                    return [
                                      _c("p", [
                                        _c("strong", [
                                          _vm._v(
                                            " " +
                                              _vm._s(results.item.title) +
                                              " "
                                          ),
                                        ]),
                                      ]),
                                      _c("p", [
                                        _vm._v(
                                          " " + _vm._s(results.item.text) + " "
                                        ),
                                      ]),
                                      _c("p", { staticClass: "text-primary" }, [
                                        _vm._v("Publicações relacionadas:"),
                                      ]),
                                      _vm._l(
                                        results.item.replies,
                                        function (reply) {
                                          return _c(
                                            "ul",
                                            {
                                              key: reply.id,
                                              staticClass: "d-flex flex",
                                              staticStyle: {
                                                "list-style": "none",
                                              },
                                            },
                                            [
                                              _c(
                                                "li",
                                                {
                                                  staticClass:
                                                    "d-flex col wrap",
                                                },
                                                [
                                                  reply.is_reference
                                                    ? _c(
                                                        "a",
                                                        {
                                                          staticStyle: {
                                                            "text-decoration":
                                                              "none",
                                                          },
                                                          attrs: {
                                                            href: reply.reference,
                                                            target: "_blank",
                                                            title: reply.text,
                                                          },
                                                        },
                                                        [
                                                          _c("b-icon", {
                                                            attrs: {
                                                              variant: "muted",
                                                              icon: "link",
                                                              "font-scale":
                                                                "1.5",
                                                            },
                                                          }),
                                                          _c("i", {
                                                            staticClass:
                                                              "bi bi-link-45deg",
                                                          }),
                                                          _vm._v(
                                                            "           \n                          " +
                                                              _vm._s(
                                                                reply.title
                                                              ) +
                                                              ". \n                        "
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _c(
                                                        "a",
                                                        {
                                                          attrs: {
                                                            href: reply.document,
                                                            terget: "_blank",
                                                            title: "reply.text",
                                                          },
                                                        },
                                                        [
                                                          _c("b-icon", {
                                                            attrs: {
                                                              variant: "muted",
                                                              icon: "file-pdf",
                                                              "font-scale":
                                                                "1.5",
                                                            },
                                                          }),
                                                          _vm._v(
                                                            "              \n                          " +
                                                              _vm._s(
                                                                reply.title
                                                              ) +
                                                              ". \n                        "
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                ]
                                              ),
                                              _c("li", [
                                                _c(
                                                  "small",
                                                  {
                                                    staticClass:
                                                      "form-text text-muted",
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "Publicado em: " +
                                                          _vm._s(
                                                            _vm._f("moment")(
                                                              reply.created_at,
                                                              "DD/MM/YYYY"
                                                            )
                                                          )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]),
                                            ]
                                          )
                                        }
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "cell(documents)",
                                  fn: function (results) {
                                    return [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: results.item.link,
                                            target: "_blank",
                                          },
                                        },
                                        [
                                          _c(
                                            "base-button",
                                            {
                                              staticClass: "row-wrap",
                                              attrs: { type: "info" },
                                            },
                                            [
                                              _c("b-icon", {
                                                attrs: {
                                                  variant: "white",
                                                  icon: "file-pdf",
                                                  "font-scale": "1.5",
                                                },
                                              }),
                                              _vm._v(
                                                "  \n                        Publicação\n                      "
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3920901385
                            ),
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "row justify-content-center" }, [
                        _c("div", { staticClass: "col-lg-9" }, [
                          _c(
                            "div",
                            [
                              _c("base-pagination", {
                                attrs: {
                                  "page-count": _vm.pagination.total,
                                  perPage: _vm.pagination.page_size,
                                  total: _vm.pagination.total,
                                  align: "center",
                                },
                                model: {
                                  value: _vm.pagination.page,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.pagination, "page", $$v)
                                  },
                                  expression: "pagination.page",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "section",
      { staticClass: "section-profile-cover section-shaped my-0" },
      [
        _c("div", {
          staticClass: "shape shape-style-1 shape-primary shape-skew alpha-4",
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }