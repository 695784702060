<template>
  <div class="profile-page">
    <section class="section-profile-cover section-shaped my-0">
      <div class="shape shape-style-1 shape-primary shape-skew alpha-4"></div>
    </section>
    <section class="section section-skew">
      <div class="container col-6">
        <card shadow class="card-profile">
          <b-row>
            <b-col>
              <div class="mb-4">
                <b-alert :show="show" :variant="color" dismissible>
                  {{ mss_alert }}
                </b-alert>
              </div>
              <b-form @submit.prevent="validate">
                <label>Informe a chave de validação:</label>
                <b-form-input
                  id="input"
                  required
                  v-model="chave"
                  placeholder="informe a chave de validação"
                  maxlength="40"
                  description="Caracteres restantes"
                ></b-form-input>

                <b-button
                  @click="validate()"
                  :disabled="chave.length === 40 ? false : true"
                  class="mt-5"
                  variant="primary"
                  >Validar</b-button
                >
              </b-form>
            </b-col>
          </b-row>
        </card>
        <div>
          <vue-recaptcha
            ref="invisibleRecaptcha"
            @verify="onVerify"
            @expired="onExpired"
            size="invisible"
            :loadRecaptchaScript="true"
            :sitekey="recaptcha"
          >
          </vue-recaptcha>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { baseApiUrl, gRecapctha } from "@/global";
import VueRecaptcha from "vue-recaptcha";
import axios from "axios";
import loadpage from "../views/components/loadPage.vue";

export default {
  name: "Certificate",
  components: { loadpage, VueRecaptcha },
  data() {
    return {
      recaptcha: gRecapctha,
      chave: "",
      cont: 40,
      mss_alert: "",
      color: "primary",
      show: false,

      loading: false,
      post: null,
      error: null,
      errorMessage: "Ocorreu um erro ao processar sua requisição...",
    };
  },

  methods: {
    validate() {
      this.$refs.invisibleRecaptcha.execute();
    },
    onVerify(responseRecaptcha) {
      this.color = "primary";
      this.show = false;

      console.log("Verify: " + responseRecaptcha);
      let url=null;
      if(this.chave.includes('OFF')){
         url = baseApiUrl + "/users/receipt-validation?fmt=pdf";
      }else{
         url = baseApiUrl + "/homologations/certificate-validation?fmt=pdf";
      }

      let payload = {
        code: this.chave,
        recaptcha: responseRecaptcha,
      };

      let header = {};
     
      const response = axios.post(url, payload,header);  
      response.then((resp) => {
        this.resetRecaptcha();
        console.log(resp);
        
        if (!this.chave.includes('OFF'))//deaclaração de cadastro de ofertante
        {
          if (resp.headers['content-type'] == 'application/json') {//off inválida
            console.log("JSON DEC")
            this.mss_alert =
              "Declaração inválida! Chave informada: \n" + this.chave;
            this.color = "danger";
            this.show = true;
            this.chave = "";
          }
          else if (resp.headers['content-type'] == 'application/pdf') {
            console.log("PDF DEC")
            header = {
              responseType: "arraybuffer",
            };
            this.mss_alert = "Declaração válida, baixando PDF para verificação... Chave informada:  " + this.chave;
            this.color = "success";
            this.show = true;
            this.chave = "";

            const fileurl = URL.createObjectURL(
              new Blob([resp.data], {
                type: "application/pdf",
              })
            );

            const link = document.createElement("a");
            link.href = fileurl;
            link.setAttribute(
              "download",
              "declaração.pdf"
            );
            document.body.appendChild(link);
            link.click();
          }//else if
        }//if OFF


        else {//certificado de homologação
          if (resp.headers['content-type'] == 'application/json') {//off inválida
            this.mss_alert = "Certificado inválido! Chave informada: \n" + this.chave;
            this.color = "danger";
            this.show = true;
            this.chave = "";
          }
          else if (resp.headers['content-type'] == 'application/pdf') {
            this.mss_alert = "Certificado válido, baixando PDF para verificação... Chave informada:  " + this.chave;
            this.color = "success";
            this.show = true;
            this.chave = "";
            const fileurl = URL.createObjectURL(
              new Blob([resp.data], {
                type: "application/pdf",
              })
            );
            const link = document.createElement("a");
            link.href = fileurl;
            link.setAttribute(
              "download",
              "comprovante.pdf"
            );
            document.body.appendChild(link);
            link.click();
          }
        }                
      });
      response.catch((err) => {
        this.resetRecaptcha();
        this.mss_alert =
          "Não foi possível validar a chave informada! Erro: " +
          err.response.status;
        this.color = "danger";
        this.show = true;
        this.chave = "";
      });
    },

    onExpired: function () {
      console.log("Expired");
      this.resetRecaptcha();
    },
    resetRecaptcha() {
      this.$refs.invisibleRecaptcha.reset(); // Direct call reset method
    },
  },
  mounted() {
    if (this.$route.query.chave) {
      this.chave = this.$route.query.chave;
      this.validate();
    }
  },
};
</script>
<style scoped>
.card-body{
  height: 20em !important;
}
#input {
  font-size: 1.6em;
}
.profile-page .card-profile {
  margin-top: -400px !important;
}

.loading {
  height: 500px;
  text-align: center;
  padding-top: 50px;
}
</style>
