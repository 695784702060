<template>
  <div class="profile-page">
    <section class="section-profile-cover section-shaped my-0">
      <div class="shape shape-style-1 shape-primary shape-skew alpha-4"></div>
    </section>
    <section class="section section-skew">
      <div class="container">
        <card shadow class="card-profile" no-body>
          <div class="px-4">
            <div class="row justify-content-start">
              <div class="col-lg-6 order-lg-1">
                <div
                  class="card-profile-stats card-profile-stats justify-content-start"
                >
                  <div>
                    <span class="description">Estado Selecionado</span>
                    <span class="heading">{{ selectedUfState }}</span>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 order-lg-3">
                <div class="card-profile-stats d-flex justify-content-center">
                  <div>
                    <span class="heading">{{ stats.notices }}</span>
                    <span class="description">Editais</span>
                  </div>
                  <div>
                    <span class="heading">{{ stats.laws }}</span>
                    <span class="description">Leis</span>
                  </div>
                  <div>
                    <span class="heading">{{ stats.publication }}</span>
                    <span class="description">Publicações</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center">
              <div class="container ct-example-row">
                <div class="row">
                  <div class="col-sm">
                    <base-radio class="mb-3" v-model="radioState" name="0"
                      ><img
                        src="https://demos.creative-tim.com/argon-design-system/assets/img/icons/flags/BR.png"
                      />
                      BR</base-radio
                    >
                  </div>
                  <div class="col-sm">
                    <base-radio class="mb-3" v-model="radioState" name="6"
                      ><img
                        src="../../public/img/icons/flags/al.png"
                        width="23px"
                        height="17px"
                      />
                      AL</base-radio
                    >
                  </div>
                  <div class="col-sm">
                    <base-radio class="mb-3" v-model="radioState" name="7"
                      ><img
                        src="../../public/img/icons/flags/ba.png"
                        width="23px"
                        height="17px"
                      />
                      BA</base-radio
                    >
                  </div>
                  <div class="col-sm">
                    <base-radio class="mb-3" v-model="radioState" name="2"
                      ><img
                        src="../../public/img/icons/flags/ce.png"
                        width="23px"
                        height="17px"
                      />
                      CE</base-radio
                    >
                  </div>
                  <div class="col-sm">
                    <base-radio class="mb-3" v-model="radioState" name="1"
                      ><img
                        src="../../public/img/icons/flags/ma.png"
                        width="23px"
                        height="17px"
                      />
                      MA</base-radio
                    >
                  </div>
                  <div class="col-sm">
                    <base-radio class="mb-3" v-model="radioState" name="8"
                      ><img
                        src="../../public/img/icons/flags/pb.png"
                        width="23px"
                        height="17px"
                      />
                      PB</base-radio
                    >
                  </div>
                  <div class="col-sm">
                    <base-radio class="mb-3" v-model="radioState" name="5"
                      ><img
                        src="../../public/img/icons/flags/pe.png"
                        width="23px"
                        height="17px"
                      />
                      PE</base-radio
                    >
                  </div>
                  <div class="col-sm">
                    <base-radio class="mb-3" v-model="radioState" name="3"
                      ><img
                        src="../../public/img/icons/flags/pi.png"
                        width="23px"
                        height="17px"
                      />
                      PI</base-radio
                    >
                  </div>
                  <div class="col-sm">
                    <base-radio class="mb-3" v-model="radioState" name="4"
                      ><img
                        src="../../public/img/icons/flags/rn.png"
                        width="23px"
                        height="17px"
                      />
                      RN</base-radio
                    >
                  </div>
                  <div class="col-sm">
                    <base-radio class="mb-3" v-model="radioState" name="9"
                      ><img
                        src="../../public/img/icons/flags/se.png"
                        width="23px"
                        height="17px"
                      />
                      SE</base-radio
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="text-left">
              <div class="container ct-example-row">
                <div class="row">
                  <div class="col-sm">
                    <base-radio
                      class="mb-3"
                      v-model="radioPublicationType"
                      name="0"
                    >
                      Todos</base-radio
                    >
                  </div>
                  <div class="col-sm">
                    <base-radio
                      class="mb-3"
                      v-model="radioPublicationType"
                      name="1"
                    >
                      Editais</base-radio
                    >
                  </div>
                  <div class="col-sm">
                    <base-radio
                      class="mb-3"
                      v-model="radioPublicationType"
                      name="2"
                    >
                      Legislação</base-radio
                    >
                  </div>
                  <div class="col-sm">
                    <base-radio
                      class="mb-3"
                      v-model="radioPublicationType"
                      name="3"
                    >
                      Publicação</base-radio
                    >
                  </div>
                </div>
              </div>
            </div>
            <div v-if="loading" class="loading">
              <loadpage></loadpage>
            </div>

            <div v-if="error" class="error">
              {{ errorMessage }}
            </div>
            <div class="py-5 text-center" v-if="post">
              <div>
                <b-table
                  class="tableData"
                  striped
                  hover
                  responsive
                  :fields="fields"
                  :items="results"
                >
                  <!-- A custom formatted column -->
                  <template #cell(title)="results">
                    <p><strong> {{ results.item.title}} </strong></p>
                    <p> {{ results.item.text}} </p>
                    <p  class="text-primary">Publicações relacionadas:</p>
                    <ul class="d-flex flex" style="list-style: none;" v-for="reply in results.item.replies" v-bind:key="reply.id">
                      <li class="d-flex col wrap">
                          <a style="text-decoration:none;"  v-if="reply.is_reference" :href="reply.reference" target="_blank" :title="reply.text"> 
                            <b-icon  variant="muted" icon="link"  font-scale="1.5"></b-icon>   
                            <i class="bi bi-link-45deg"></i>           
                            {{ reply.title }}. 
                          </a>                                                   
                          <a v-else :href="reply.document" terget="_blank" title="reply.text"> 
                            <b-icon  variant="muted" icon="file-pdf"  font-scale="1.5"></b-icon>              
                            {{ reply.title }}. 
                          </a>                                                  
                      </li>
                      <li><small  class="form-text text-muted"> <span>Publicado em: {{ reply.created_at | moment("DD/MM/YYYY")}}</span> </small></li>
                    </ul>
                  </template>

                  <template #cell(documents)="results">
                 
                      <a :href="results.item.link" target="_blank">
                        <base-button type="info" class="row-wrap"> 
                          <b-icon  variant="white" icon="file-pdf"  font-scale="1.5">                          
                          </b-icon>  
                          Publicação
                        </base-button>
                      </a>
                   
                      <!-- <div v-for="(reply,i) in results.item.replies" :key="reply.id"  class="">
                        <b-tooltip target="button-1" title="Online!"></b-tooltip>
                        <a v-if="reply.is_reference" :href="reply.reference" target="_blank" >
                          <b-button type="secondary" class="mt-1" id="button-1">
                            <b-icon  variant="primary" icon="file-pdf"  font-scale="1.5">                          
                            </b-icon>  
                            Anexo {{ i+1 }}
                          </b-button>
                          <small  class="form-text text-muted"> Enviado em  {{ reply.created_at | moment("DD/MM/YYYY")}}</small>
                         
                        </a>
                        <a v-else :href="reply.document" target="_blank" >
                          <b-button type="secondary" class="mt-1">   
                            <b-icon  variant="primary" icon="flink45deg"  font-scale="1.5">                          
                            </b-icon>  
                            Anexo {{ i+1 }}
                          </b-button>
                          <small  class="form-text text-muted"> Enviado em  {{ reply.created_at | moment("DD/MM/YYYY")}}</small>
                        </a>
                        <a  :href="reply.document" target="_blank" >
                          <b-button type="secondary" class="mt-1" topleft v-b-tooltip.hover title="Tooltip directive content"> 
                            <b-icon  variant="primary" icon="flink45deg"  font-scale="1.5">                          
                            </b-icon>  
                            Anexo {{ i+1 }}
                          </b-button>
                          <small  class="form-text text-muted"> Enviado em  {{ reply.created_at | moment("DD/MM/YYYY")}}</small>
                        </a>
                      </div> -->
                   
                  </template>
                </b-table>
              </div>
              <div class="row justify-content-center">
                <div class="col-lg-9">
                  <div>
                    <base-pagination
                      :page-count="pagination.total"
                      :perPage="pagination.page_size"
                      :total="pagination.total"
                      v-model="pagination.page"
                      align="center"
                    ></base-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </section>
  </div>
</template>
<script>
import { baseApiUrl } from "@/global";
import axios from "axios";
import { csrfToken } from "@/global";
import loadpage from "../views/components/loadPage.vue";

export default {
  /*  edital notices = 1
      Legislação laws = 2
      Publicação publication = 3
      */

  name: "VirutalLibrary",
  components: { loadpage },
  data() {
    return {
      loading: false,
      post: null,
      error: null,
      errorMessage: "Ocorreu um erro ao processar sua requisição...",
      radioState: null,
      radioPublicationType: "0",
      selectedUfState: "",
      pagination: {
        page: 1,
        page_size: 10,
        next: "",
        previous: "",
        count: 0,
        total: 0,
      },
      stats: {
        laws: 0,
        standarts: 0,
        resolution: 0,
        notices: 0,
        ordinance: 0,
        publication: 0,
      },
      retorno: {},
      fields: [
        {
          key: "created_at",
          label: "Data",
          sortable: true,
        },
        {
          key: "title",
          label: "Título",
          sortable: true,
        },
        {
          key: "publication_type",
          label: "Tipo",
          sortable: true,
        },
        {
          key: "scope",
          label: "Escopo",
          sortable: true,
        },
        {
          key: "virtual_library",
          label: "Estado",
          sortable: true,
        },
        {
          key: "documents",
          label: "Documentos",
          sortable: true,
        },
      ],
      ufStates: {
        0: {
          id: "0",
          name: "Todos",
          acronym: "br",
        },
        6: {
          id: "6",
          name: "Alagoas",
          acronym: "al",
        },
        7: {
          id: "7",
          name: "Bahia",
          acronym: "ba",
        },
        2: {
          id: "2",
          name: "Ceará",
          acronym: "ce",
        },
        1: {
          id: "1",
          name: "Maranhão",
          acronym: "ma",
        },
        8: {
          id: "8",
          name: "Paraíba",
          acronym: "pb",
        },
        5: {
          id: 5,
          name: "Pernambuco",
          acronym: "pe",
        },
        3: {
          id: "3",
          name: "Piauí",
          acronym: "pi",
        },
        4: {
          id: "4",
          name: "Rio Grande do Norte",
          acronym: "rn",
        },
        9: {
          id: "9",
          name: "Sergipe",
          acronym: "se",
        },
      },
      queryUrl: {
        virtual_library: 0,
        type: 0,
      },
      results: {},
    };
  },
  watch: {
    radioState: function () {
      this.buscar(1);
    },
    radioPublicationType: function () {
      this.buscar(2);
    },
    "pagination.page": function () {
      this.buscar(0);
    },
  },
  methods: {
    buscar: function (type) {
      this.error = false;
      this.loading = true;
      this.post = false;

      if (type != 0) {
        this.pagination.page = 1;
      }

      this.selectedUfState = this.ufStates[this.radioState].name;
      const preQuery = baseApiUrl + "/virtual-library/publication/";

      let posQuery =
        "?page=" +
        this.pagination.page +
        "&page_size=" +
        this.pagination.page_size;

      if (this.radioState != 0) {
        posQuery =
          "?page=" +
          this.pagination.page +
          "&page_size=" +
          this.pagination.page_size +
          "&virtual_library=" +
          this.radioState;
      }
      if (this.radioPublicationType != 0) {
        posQuery = posQuery + "&publication_type=" + this.radioPublicationType;
      }
      let query = preQuery + posQuery;
      //console.log(query);
      axios
        .get(query, { _token: csrfToken })
        .then((response) => {
          this.retorno = response.data;
          this.pagination.count = this.retorno.count;
          this.pagination.next = this.retorno.next;
          this.pagination.previous = this.retorno.previous;
          this.results = this.retorno.results;
          //console.log(this.results);

          for (let i in this.stats) {
            this.stats[i] = 0;
          }
          for (let i in this.results) {
            //console.log(this.results[i]);
            if (this.results[i].publication_type == 1) {
              this.stats.notices++;
            }
            if (this.results[i].publication_type == 2) {
              this.stats.laws++;
            }
            if (this.results[i].publication_type == 3) {
              this.stats.publication++;
            }

            if (this.results[i].virtual_library == 1) {
              this.results[i].virtual_library = "MA";
            }
            if (this.results[i].virtual_library == 2) {
              this.results[i].virtual_library = "CE";
            }
            if (this.results[i].virtual_library == 3) {
              this.results[i].virtual_library = "PI";
            }
            if (this.results[i].virtual_library == 4) {
              this.results[i].virtual_library = "RN";
            }
            if (this.results[i].virtual_library == 5) {
              this.results[i].virtual_library = "PE";
            }
            if (this.results[i].virtual_library == 6) {
              this.results[i].virtual_library = "AL";
            }
            if (this.results[i].virtual_library == 7) {
              this.results[i].virtual_library = "BA";
            }
            if (this.results[i].virtual_library == 8) {
              this.results[i].virtual_library = "PB";
            }
            if (this.results[i].virtual_library == 9) {
              this.results[i].virtual_library = "SE";
            }

            if (this.results[i].publication_type == 1) {
              this.results[i].publication_type = "Edital";
            }
            if (this.results[i].publication_type == 2) {
              this.results[i].publication_type = "Lei";
            }
            if (this.results[i].publication_type == 3) {
              this.results[i].publication_type = "Publicação";
            }

            if (this.results[i].scope == 1) {
              this.results[i].scope = "Estadual";
            }
            if (this.results[i].scope == 2) {
              this.results[i].scope = "Nacional";
            }

            const d = new Date(this.results[i].created_at);
            const ye = new Intl.DateTimeFormat("pt-BR", {
              year: "2-digit",
            }).format(d);
            const mo = new Intl.DateTimeFormat("pt-BR", {
              month: "2-digit",
            }).format(d);
            const da = new Intl.DateTimeFormat("pt-BR", {
              day: "2-digit",
            }).format(d);
            this.results[i].created_at = `${da}/${mo}/${ye}`;


            if (this.results[i].is_reference) {
              this.results[i].link = this.results[i].reference;
            } else {
              this.results[i].link = this.results[i].document;
            }
          }

          //============= montagem da página

          this.pagination.total =
            this.pagination.count / this.pagination.page_size;

          //================================

          this.loading = false;
          this.post = true;
        })
        .catch((error) => {
          console.log(error);
          this.post = false;
          this.loading = false;
          this.error = true;
        });
    },
    async getStreetAddressFrom(lat, long) {
      let address = "";
      let i = null;
      let findIt = false;
      try {
        var { data } = await axios.get(
          "https://nominatim.openstreetmap.org/reverse?format=json&lat=" +
            lat +
            "&lon=" +
            long
        );
        if (data.error_message) {
          console.log(data.error_message);
        } else {
          address = data.address.state;
          //console.log(address);
          for (i in this.ufStates) {
            if (address == this.ufStates[i].name) {
              this.radioState = this.ufStates[i].id;
              this.selectedUfState = this.ufStates[i].name;
              findIt = true;
            }
          }
          if (!findIt) {
            this.radioState = this.ufStates[0].id;
            this.selectedUfState = this.ufStates[0].name;
          }
          //this.buscar();
        }
      } catch (error) {
        console.log(error.message);
        this.radioState = this.ufStates[0].id;
        this.selectedUfState = this.ufStates[0].name;
      }
    },
  },
  created() {
    this.error = false;
    this.loading = true;
    this.post = false;
    navigator.geolocation.getCurrentPosition(
      (position) => {
        //console.log(position.coords.latitude);
        //console.log(position.coords.longitude);
        this.getStreetAddressFrom(
          position.coords.latitude,
          position.coords.longitude
        );
      },
      (error) => {
        console.log(error.message);
        this.getStreetAddressFrom(0, 0);
      }
    );
  }, //created
};
</script>
<style scoped>
a {
  color:rgb(92, 88, 88); 
  text-decoration: none;  
}
ul li {
  list-style: none;  
}
.profile-page .card-profile {
  margin-top: -400px !important;
}
.tableData {
  font-size: 0.9rem;
  vertical-align: middle;
  text-align: left;
}
.titulo {
  font-size: 1.5rem;
}
.loading {
  height: 500px;
  text-align: center;
  padding-top: 50px;
}

.column {
  flex-direction: column;
}
.row-wrap {
	flex-flow: row wrap;
}

</style>
