<template>
  <div>
    <div class="position-relative">
      <!-- shape Hero -->

      <section class="section-shaped my-0">
        <div class="shape shape-style-1 shape-primary shape-skew"></div>
        <div class="container shape-container d-flex">
          <div class="col px-0">
            <div class="row">
              <div class="col-lg-12">
                <h1 class="display-3 text-white text-center">
                  Sistema de Informação Regional da Agricultura Familiar no
                  Nordeste
                </h1>
                <p class="lead text-white text-center">
                  Ampliação da participação de agricultores familiares no
                  processo de compras governamentais e disponibilização de seus
                  produtos a outros mercados.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section section-lg pt-lg-0 mt--200" id="indicators">
        <div class="container">
          <h2 class="display-4 text-white">Nossos Números:</h2>
          <div class="row justify-content-center">
            <div class="col-lg-12">
              <div class="row row-grid">
                <div class="col-lg-2">
                  <card class="border-0 statCard" hover shadow>
                    <div class="stats-title">
                      <label class="text-primary text-uppercase">
                        Ofertantes Cadastrados
                      </label>
                    </div>
                    <p class="siraf_stats" v-if="!loading">
                      {{ activeIndicators.farmers_count }}
                    </p>
                    <p v-else>                      
                      <b-icon class="bi-spin"  variant="muted" icon="arrow-clockwise" animation="spin" font-scale="3"></b-icon>             
                    </p>
                  </card>
                </div>
                <div class="col-lg-2">
                  <card class="border-0 statCard" hover shadow>
                    <div>
                      <label class="text-primary text-uppercase">
                        Grupos Informais Cadastrados
                      </label>
                    </div>
                    <p class="siraf_stats" v-if="!loading">
                      {{ activeIndicators.natural_groups_count }}
                    </p>
                    <p v-else>                      
                      <b-icon class="bi-spin"  variant="muted" icon="arrow-clockwise" animation="spin" font-scale="3"></b-icon>             
                    </p>
                  </card>
                </div>
                <div class="col-lg-2">
                  <card class="border-0 statCard" hover shadow>
                    <label class="text-primary text-uppercase">
                      Cooperativas Cadastradas
                    </label>
                    <p class="siraf_stats" v-if="!loading">
                      {{ activeIndicators.cooperative_count }}
                    </p>
                    <p v-else>                      
                      <b-icon class="bi-spin"  variant="muted" icon="arrow-clockwise" animation="spin" font-scale="3"></b-icon>             
                    </p>
                  </card>
                </div>
                <div class="col-lg-2">
                  <card class="border-0 statCard" hover shadow>
                    <label class="text-primary text-uppercase">
                      Associações Cadastradas
                    </label>
                    <p class="siraf_stats" v-if="!loading">
                      {{ activeIndicators.association_count }}
                    </p>
                    <p v-else>                      
                      <b-icon class="bi-spin"  variant="muted" icon="arrow-clockwise" animation="spin" font-scale="3"></b-icon>             
                    </p>
                  </card>
                </div>
                <div class="col-lg-2">
                  <card class="border-0 statCard" hover shadow>
                    <label class="text-primary text-uppercase">
                      Ofertas Cadastradas
                    </label>
                    <p class="siraf_stats" v-if="!loading">
                      {{ activeIndicators.offers_count }}
                    </p>
                    <p v-else>                      
                      <b-icon class="bi-spin"  variant="muted" icon="arrow-clockwise" animation="spin" font-scale="3"></b-icon>             
                    </p>
                  </card>
                </div>
                <div class="col-lg-2">
                  <card class="border-0 statCard" hover shadow>
                    <label class="text-primary text-uppercase">
                      Produtos Cadastrados
                    </label>
                    
                    <p class="siraf_stats" v-if="!loading">
                      {{ activeIndicators.products_count }}
                    </p>
                    <p v-else>                      
                      <b-icon class="bi-spin"  variant="muted" icon="arrow-clockwise" animation="throb" font-scale="3"></b-icon>             
                    </p>

                  </card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- 1st Hero Variation -->
    </div>
  </div>
</template>

<script>
import { baseApiUrl, baseURL } from "@/global";
import axios from "axios";

//import Chart from "@/views/components/Chart";
//import Cities from "@/views/components/maps/cities"

export default {
  name: "home",
  components: {
    //chart: Chart,
  },

  data() {
    return {
      loading:true,
      baseURL,
      pointedLocation: null,
      tooltipStyle: null,
      selectedLocation: null,
      radioState: "0",
      selectedState: "Todo o Nordeste",
      radioCategory: "0",
      radioProduct: "0",
      checkGender: {
        male: false,
        female: false,
        other: false,
        ni: false,
      },
      checkDeclaration: {
        i: false,
        q: false,
        wo: false,
      },
      checkFunding: {
        fida: false,
        bid: false,
        bird: false,
        wo: false,
      },
      checkOffer: {
        individual: false,
        informal: false,
        association: false,
        //cooperative: true,
      },
      checkProductMonth: [
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
      ],
      sliderAge: [18, 80],      
      mapAddress: 2,
      data: {},
      //cities: Cities,
      indicators: {
        association_count: 0,
        cooperative_count: 0,
        farmers_count: 0,
        natural_groups_count: 0,
        offers_count: 0,
        products_count: 0,
        system_accounts_count: 0,
        cities_with_farmers_count: 0,
        association_by_states_count: [],
        cooperative_by_states_count: [],
        farmers_by_states_count: [],
        natural_groups_by_states_count: [],
        offers_by_states_count: [],
        products_by_states_count: [],
        system_accounts_by_states_count: [],
        farmers_by_cities_count: [],
      },
      activeIndicators: {
        association_count: 0,
        cooperative_count: 0,
        farmers_count: 0,
        natural_groups_count: 0,
        offers_count: 0,
        products_count: 0,
        system_accounts_count: 0,
        cities_with_farmers_count: 0,
      },
      ufStates: {
        0: {
          id: "0",
          ibgeId: 2,
          name: "Todos",
          acronym: "br",
        },
        6: {
          id: "6",
          ibgeId: 27,
          name: "Alagoas",
          acronym: "al",
          association_count: 0,
          cooperative_count: 0,
          farmers_count: 0,
          natural_groups_count: 0,
          products_count: 0,
          offers_count: 0,
        },
        7: {
          id: "7",
          ibgeId: 29,
          name: "Bahia",
          acronym: "ba",
          association_count: 0,
          cooperative_count: 0,
          farmers_count: 0,
          natural_groups_count: 0,
          products_count: 0,
          offers_count: 0,
        },
        2: {
          id: "2",
          ibgeId: 23,
          name: "Ceará",
          acronym: "ce",
          association_count: 0,
          cooperative_count: 0,
          farmers_count: 0,
          natural_groups_count: 0,
          products_count: 0,
          offers_count: 0,
        },
        1: {
          id: "1",
          ibgeId: 21,
          name: "Maranhão",
          acronym: "ma",
          association_count: 0,
          cooperative_count: 0,
          farmers_count: 0,
          natural_groups_count: 0,
          products_count: 0,
          offers_count: 0,
        },
        8: {
          id: "8",
          ibgeId: 25,
          name: "Paraíba",
          acronym: "pb",
          association_count: 0,
          cooperative_count: 0,
          farmers_count: 0,
          natural_groups_count: 0,
          products_count: 0,
          offers_count: 0,
        },
        5: {
          id: 5,
          ibgeId: 26,
          name: "Pernambuco",
          acronym: "pe",
          association_count: 0,
          cooperative_count: 0,
          farmers_count: 0,
          natural_groups_count: 0,
          products_count: 0,
          offers_count: 0,
        },
        3: {
          id: "3",
          ibgeId: 22,
          name: "Piauí",
          acronym: "pi",
          association_count: 0,
          cooperative_count: 0,
          farmers_count: 0,
          natural_groups_count: 0,
          products_count: 0,
          offers_count: 0,
        },
        4: {
          id: "4",
          ibgeId: 24,
          name: "Rio Grande do Norte",
          acronym: "rn",
          association_count: 0,
          cooperative_count: 0,
          farmers_count: 0,
          natural_groups_count: 0,
          products_count: 0,
          offers_count: 0,
        },
        9: {
          id: "9",
          ibgeId: 28,
          name: "Sergipe",
          acronym: "se",
          association_count: 0,
          cooperative_count: 0,
          farmers_count: 0,
          natural_groups_count: 0,
          products_count: 0,
          offers_count: 0,
        },
      },
    };
  },
  watch: {
    radioState: function () {
      this.formatData();
    },
    selectedLocation: function () {
      //console.log(this.selectedLocation);
      for (let i in this.ufStates) {
        if (this.ufStates[i].acronym == this.selectedLocation) {
          this.radioState = this.ufStates[i].id;
        }
      }
    },
  },
  methods: {
    handler() {
      var args = arguments;
      for (var arg of args) {
        if (arg instanceof Function) {
          arg();
        }
      }
    },
    getIndicadores() {          

      let query = baseApiUrl + "/opendata/indicators?fmt=json";
      //console.log("URL da requisição: " + query);
      axios
        .get(query)
        .then((response) => {
          this.retorno = response.data;
          this.indicators.association_count = response.data.association_count;
          this.indicators.cooperative_count = response.data.cooperative_count;
          this.indicators.farmers_count = response.data.farmers_count;
          this.indicators.natural_groups_count =
            response.data.natural_groups_count;
          this.indicators.products_count = response.data.products_count;
          this.indicators.offers_count = response.data.offers_count;
          this.indicators.cities_with_farmers_count =
            response.data.cities_with_farmers_count;

          this.indicators.association_by_states_count =
            response.data.association_by_states_count;
          this.indicators.cooperative_by_states_count =
            response.data.cooperative_by_states_count;
          this.indicators.farmers_by_states_count =
            response.data.farmers_by_states_count;
          this.indicators.natural_groups_by_states_count =
            response.data.natural_groups_by_states_count;
          this.indicators.offers_by_states_count =
            response.data.offers_by_states_count;
          this.indicators.products_by_states_count =
            response.data.products_by_states_count;
          this.indicators.system_accounts_by_states_count =
            response.data.system_accounts_by_states_count;
          this.indicators.farmers_by_cities_count =
            response.data.farmers_by_cities_count;

          this.activeIndicators = Object.assign({}, this.indicators);

          //console.log(this.activeIndicators);
          this.formatData();
          this.loading=false;
        })
        .catch((error) => {
          //console.log(error);
          this.loading=false;
        });
    },
    formatData: function () {
      //console.log(this.radioState);
      if (this.radioState == 0) {
        //console.log(this.indicators);
        this.activeIndicators = Object.assign({}, this.indicators);
      } else {
        let array = this.indicators.association_by_states_count;
        array.forEach((element) => {
          if (element["uf"] == this.ufStates[this.radioState].ibgeId)
            this.ufStates[this.radioState].association_count =
              element["quantity"];
        });

        array = this.indicators.cooperative_by_states_count;
        array.forEach((element) => {
          if (element["uf"] == this.ufStates[this.radioState].ibgeId)
            this.ufStates[this.radioState].cooperative_count =
              element["quantity"];
        });

        array = this.indicators.farmers_by_states_count;
        array.forEach((element) => {
          if (element["uf"] == this.ufStates[this.radioState].ibgeId)
            this.ufStates[this.radioState].farmers_count = element["quantity"];
        });

        array = this.indicators.natural_groups_by_states_count;
        array.forEach((element) => {
          if (element["uf"] == this.ufStates[this.radioState].ibgeId)
            this.ufStates[this.radioState].natural_groups_count =
              element["quantity"];
        });

        array = this.indicators.products_by_states_count;
        array.forEach((element) => {
          if (element["uf"] == this.ufStates[this.radioState].ibgeId)
            this.ufStates[this.radioState].products_count = element["quantity"];
        });

        array = this.indicators.offers_by_states_count;
        array.forEach((element) => {
          if (element["uf"] == this.ufStates[this.radioState].ibgeId)
            this.ufStates[this.radioState].offers_count = element["quantity"];
        });

        this.activeIndicators = Object.assign(
          {},
          this.ufStates[this.radioState]
        );
        this.activeIndicators.products_count = this.indicators.products_count;
        //console.log("indicador: " + this.ufStates[this.radioState].natural_groups_count);
      }
      this.selectedState = this.ufStates[this.radioState].name;
      this.selectedLocation = this.ufStates[this.radioState].ibgeId;

      // carregamento de dados para o mapa //

      this.data = Object.assign({}, this.indicators);

      //console.log(this.data);

      this.mapAddress = this.ufStates[this.radioState].ibgeId;
    },
  },
  created() {
    /* console.log("INSTÂNCIA: " + process.env.VUE_APP_TITLE);
    console.log("NODE_ENV: " + process.env.NODE_ENV);
    console.log("BASE_API_URL: " + process.env.VUE_APP_BASE_API_URL);
    console.log("BASE_URL: " + process.env.VUE_APP_BASE_URL); */
    this.mapAddress = 0;
    //console.log(this.mapAddress);
    this.getIndicadores();
  },
  mounted() {
    if (this.$route.query.chave) {
      console.log('chave' + this.$route.query.chave)

      this.$router.push({ name: 'certificado', query: { chave: this.$route.query.chave } })
    }
  },
};
</script>


<style>

.card-body {
  height: 170px !important;

}

.siraf_stats {
  font-size: 1.5rem;
  font-weight: 500;
  align-self: baseline;
}

#map {
  height: 180px;
}

.statCard label {
  font-size: 0.8rem;
  font-weight: 500;
}


.formMargin {
  margin-top: 20px;
  margin-bottom: 10px;
}

.bi-spin {
  -webkit-animation: bi-spin 2s infinite linear;
  animation: bi-spin 2s infinite linear;
}
.bi-pulse {
  -webkit-animation: bi-spin 1s infinite steps(8);
  animation: bi-spin 1s infinite steps(8);
}
@-webkit-keyframes bi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes bi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
</style>
