/*!

=========================================================
* Vue Argon Design System - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system/blob/master/LICENSE.md)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Argon from "./plugins/argon-kit";
//import './registerServiceWorker'
import './config/axios'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueGtag from "vue-gtag";

import Highcharts from "highcharts";
import Maps from "highcharts/modules/map";
import HighchartsVue from "highcharts-vue";
import ExportingHighcharts from "highcharts/modules/exporting";
import loadDrillDown from 'highcharts/modules/drilldown'

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

ExportingHighcharts(Highcharts);
loadDrillDown(Highcharts)
Maps(Highcharts);

export const gTag = process.env.VUE_APP_GTAG

Vue.use(VueGtag, {
  config: {
    id: gTag, params: {
      send_page_view: false
    }
  }
}, router);


Vue.config.productionTip = false;
Vue.use(Argon);
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(HighchartsVue);

Vue.use(require('vue-moment'));

Sentry.init({
  Vue,
  dsn: "https://11591624eafd4e738cdb4979b1bdf12f@o1047693.ingest.sentry.io/6024937",
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [process.env.VUE_APP_BASE_URL, /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  logErrors: true,
  release: "sirafne-homepage@" + process.env.VUE_APP_RELEASE,
});

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
