var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "section",
      { staticClass: "section section section-shaped my-0 overflow-hidden" },
      [
        _vm._m(0),
        _c("div", { staticClass: "container py-5" }, [
          _c("div", { staticClass: "row row-grid align-items-center" }, [
            _vm._m(1),
            _c(
              "div",
              { staticClass: "col-lg-6 order-lg-1" },
              [
                _c("div", { staticClass: "d-flex px-3" }, [
                  _c(
                    "div",
                    [
                      _c("icon", {
                        staticClass: "bg-gradient-white",
                        attrs: {
                          name: "ni ni-chat-round",
                          size: "lg",
                          color: "success",
                          shadow: "",
                          rounded: "",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._m(2),
                ]),
                _c(
                  "card",
                  {
                    staticClass: "shadow-lg--hover mt-5",
                    attrs: { shadow: "" },
                  },
                  [
                    _c("div", { staticClass: "d-flex px-3" }, [
                      _c(
                        "div",
                        [
                          _c("icon", {
                            attrs: {
                              name: "ni ni-email-83",
                              gradient: "success",
                              color: "white",
                              shadow: "",
                              rounded: "",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "pl-4" }, [
                        _c("h5", { staticClass: "title text-success" }, [
                          _vm._v(
                            "Está com problemas, dificuldades ao utilizar o SIRAF-NE?\n                                    Envie um e-mail para:"
                          ),
                        ]),
                        _c("p", [
                          _c("strong", [_vm._v("suporte.sirafne@uern.br")]),
                        ]),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "card",
                  {
                    staticClass: "shadow-lg--hover mt-5",
                    attrs: { shadow: "" },
                  },
                  [
                    _c("div", { staticClass: "d-flex px-3" }, [
                      _c(
                        "div",
                        [
                          _c("icon", {
                            attrs: {
                              name: "ni ni-send",
                              gradient: "warning",
                              color: "white",
                              shadow: "",
                              rounded: "",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "pl-4" }, [
                        _c("h5", { staticClass: "title text-warning" }, [
                          _vm._v(
                            "Deseja mais informações sobre o SIRAF-NE? Envie um e-mail\n                                    para:"
                          ),
                        ]),
                        _c("p", [_c("strong", [_vm._v("sirafne@uern.br")])]),
                      ]),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "shape shape-style-1 bg-gradient-success shape-skew" },
      [_c("span"), _c("span"), _c("span"), _c("span"), _c("span"), _c("span")]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-6 order-lg-2 ml-lg-auto" }, [
      _c("div", { staticClass: "position-relative pl-md-5" }, [
        _c("img", {
          staticClass: "img-center img-fluid",
          attrs: { src: "img/ill/ill-2.svg" },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pl-4" }, [
      _c("h4", { staticClass: "display-3 text-white" }, [_vm._v("Contatos")]),
      _c("p", { staticClass: "text-white" }, [
        _vm._v(
          "Caso necessite tirar alguma dúvida sobre seu cadastro no SIRAF-NE, nos\n                                envie um e-mail!"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }