<template>
    <div>
        <section class="section section section-shaped my-0 overflow-hidden">
            <div class="shape shape-style-1 bg-gradient-success shape-skew">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div class="container py-5">
                <div class="row row-grid align-items-center">
                    <div class="col-md-6 order-lg-2 ml-lg-auto">
                        <div class="position-relative pl-md-5">
                            <img src="img/ill/ill-2.svg" class="img-center img-fluid">
                        </div>
                    </div>
                    <div class="col-lg-6 order-lg-1">
                        <div class="d-flex px-3">
                            <div>
                                <icon name="ni ni-chat-round" size="lg" class="bg-gradient-white" color="success" shadow
                                    rounded></icon>
                            </div>
                            <div class="pl-4">
                                <h4 class="display-3 text-white">Contatos</h4>
                                <p class="text-white">Caso necessite tirar alguma dúvida sobre seu cadastro no SIRAF-NE, nos
                                    envie um e-mail!</p>
                            </div>
                        </div>
                        <card shadow class="shadow-lg--hover mt-5">
                            <div class="d-flex px-3">
                                <div>
                                    <icon name="ni ni-email-83" gradient="success" color="white" shadow rounded></icon>
                                </div>
                                <div class="pl-4">
                                    <h5 class="title text-success">Está com problemas, dificuldades ao utilizar o SIRAF-NE?
                                        Envie um e-mail para:</h5>
                                    <p><strong>suporte.sirafne@uern.br</strong></p>
                                </div>
                            </div>
                        </card>
                        <card shadow class="shadow-lg--hover mt-5">
                            <div class="d-flex px-3">
                                <div>
                                    <icon name="ni ni-send" gradient="warning" color="white" shadow rounded></icon>
                                </div>
                                <div class="pl-4">
                                    <h5 class="title text-warning">Deseja mais informações sobre o SIRAF-NE? Envie um e-mail
                                        para:</h5>
                                    <p><strong>sirafne@uern.br</strong></p>
                                </div>
                            </div>
                        </card>
                    </div>
                </div>



            </div>

        </section>
        <!--
        <section class="section section section-shaped my-0 overflow-hidden">
            <div class="container py-0">

                <div class="d-flex px-3">
                    <div>
                        <icon name="ni ni-notification-70" size="lg" class="bg-gradient-success" color="white" shadow
                            rounded>
                        </icon>
                    </div>
                    <div class="pl-4">
                        <h5 class="display-3 text-success">Perguntas mais frequentes:</h5>
                    </div>
                </div>
                <div class="accordion mt-5" role="tablist">
                    <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button block v-b-toggle.accordion-1 variant="success" class="text-left">Pergunta 1
                            </b-button>
                        </b-card-header>
                        <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                               <b-card-text>I start opened because <code>visible</code> is <code>true</code></b-card-text>
                                <b-card-text>{{ text }}</b-card-text>
                            </b-card-body>
                        </b-collapse>
                    </b-card>

                    <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button block v-b-toggle.accordion-2 variant="success" class="text-left">Pergunta 2
                            </b-button>
                        </b-card-header>
                        <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                                <b-card-text>{{ text }}</b-card-text>
                            </b-card-body>
                        </b-collapse>
                    </b-card>

                    <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button block v-b-toggle.accordion-3 variant="success" class="text-left">Pergunta 3</b-button>
                        </b-card-header>
                        <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                                <b-card-text>{{ text }}</b-card-text>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                </div>
            </div>

        </section>
    -->
    </div>
</template>
<script>
export default {
    data() {
        return {
            text: `
            Em desenvolvimento. Em desenvolvimento. Em desenvolvimento. Em desenvolvimento.
            Em desenvolvimento. Em desenvolvimento. Em desenvolvimento. Em desenvolvimento.
            Em desenvolvimento. Em desenvolvimento. Em desenvolvimento. Em desenvolvimento.        
        `
        }
    }
};
</script>
<style scoped>
.card-body {
    overflow: auto;
    scroll-behavior: smooth;

}
</style>
