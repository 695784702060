var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "position-relative" }, [
      _vm._m(0),
      _c(
        "section",
        {
          staticClass: "section section-lg pt-lg-0 mt--200",
          attrs: { id: "indicators" },
        },
        [
          _c("div", { staticClass: "container" }, [
            _c("h2", { staticClass: "display-4 text-white" }, [
              _vm._v("Nossos Números:"),
            ]),
            _c("div", { staticClass: "row justify-content-center" }, [
              _c("div", { staticClass: "col-lg-12" }, [
                _c("div", { staticClass: "row row-grid" }, [
                  _c(
                    "div",
                    { staticClass: "col-lg-2" },
                    [
                      _c(
                        "card",
                        {
                          staticClass: "border-0 statCard",
                          attrs: { hover: "", shadow: "" },
                        },
                        [
                          _c("div", { staticClass: "stats-title" }, [
                            _c(
                              "label",
                              { staticClass: "text-primary text-uppercase" },
                              [
                                _vm._v(
                                  "\n                      Ofertantes Cadastrados\n                    "
                                ),
                              ]
                            ),
                          ]),
                          !_vm.loading
                            ? _c("p", { staticClass: "siraf_stats" }, [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(_vm.activeIndicators.farmers_count) +
                                    "\n                  "
                                ),
                              ])
                            : _c(
                                "p",
                                [
                                  _c("b-icon", {
                                    staticClass: "bi-spin",
                                    attrs: {
                                      variant: "muted",
                                      icon: "arrow-clockwise",
                                      animation: "spin",
                                      "font-scale": "3",
                                    },
                                  }),
                                ],
                                1
                              ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-lg-2" },
                    [
                      _c(
                        "card",
                        {
                          staticClass: "border-0 statCard",
                          attrs: { hover: "", shadow: "" },
                        },
                        [
                          _c("div", [
                            _c(
                              "label",
                              { staticClass: "text-primary text-uppercase" },
                              [
                                _vm._v(
                                  "\n                      Grupos Informais Cadastrados\n                    "
                                ),
                              ]
                            ),
                          ]),
                          !_vm.loading
                            ? _c("p", { staticClass: "siraf_stats" }, [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm.activeIndicators.natural_groups_count
                                    ) +
                                    "\n                  "
                                ),
                              ])
                            : _c(
                                "p",
                                [
                                  _c("b-icon", {
                                    staticClass: "bi-spin",
                                    attrs: {
                                      variant: "muted",
                                      icon: "arrow-clockwise",
                                      animation: "spin",
                                      "font-scale": "3",
                                    },
                                  }),
                                ],
                                1
                              ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-lg-2" },
                    [
                      _c(
                        "card",
                        {
                          staticClass: "border-0 statCard",
                          attrs: { hover: "", shadow: "" },
                        },
                        [
                          _c(
                            "label",
                            { staticClass: "text-primary text-uppercase" },
                            [
                              _vm._v(
                                "\n                    Cooperativas Cadastradas\n                  "
                              ),
                            ]
                          ),
                          !_vm.loading
                            ? _c("p", { staticClass: "siraf_stats" }, [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm.activeIndicators.cooperative_count
                                    ) +
                                    "\n                  "
                                ),
                              ])
                            : _c(
                                "p",
                                [
                                  _c("b-icon", {
                                    staticClass: "bi-spin",
                                    attrs: {
                                      variant: "muted",
                                      icon: "arrow-clockwise",
                                      animation: "spin",
                                      "font-scale": "3",
                                    },
                                  }),
                                ],
                                1
                              ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-lg-2" },
                    [
                      _c(
                        "card",
                        {
                          staticClass: "border-0 statCard",
                          attrs: { hover: "", shadow: "" },
                        },
                        [
                          _c(
                            "label",
                            { staticClass: "text-primary text-uppercase" },
                            [
                              _vm._v(
                                "\n                    Associações Cadastradas\n                  "
                              ),
                            ]
                          ),
                          !_vm.loading
                            ? _c("p", { staticClass: "siraf_stats" }, [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm.activeIndicators.association_count
                                    ) +
                                    "\n                  "
                                ),
                              ])
                            : _c(
                                "p",
                                [
                                  _c("b-icon", {
                                    staticClass: "bi-spin",
                                    attrs: {
                                      variant: "muted",
                                      icon: "arrow-clockwise",
                                      animation: "spin",
                                      "font-scale": "3",
                                    },
                                  }),
                                ],
                                1
                              ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-lg-2" },
                    [
                      _c(
                        "card",
                        {
                          staticClass: "border-0 statCard",
                          attrs: { hover: "", shadow: "" },
                        },
                        [
                          _c(
                            "label",
                            { staticClass: "text-primary text-uppercase" },
                            [
                              _vm._v(
                                "\n                    Ofertas Cadastradas\n                  "
                              ),
                            ]
                          ),
                          !_vm.loading
                            ? _c("p", { staticClass: "siraf_stats" }, [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(_vm.activeIndicators.offers_count) +
                                    "\n                  "
                                ),
                              ])
                            : _c(
                                "p",
                                [
                                  _c("b-icon", {
                                    staticClass: "bi-spin",
                                    attrs: {
                                      variant: "muted",
                                      icon: "arrow-clockwise",
                                      animation: "spin",
                                      "font-scale": "3",
                                    },
                                  }),
                                ],
                                1
                              ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-lg-2" },
                    [
                      _c(
                        "card",
                        {
                          staticClass: "border-0 statCard",
                          attrs: { hover: "", shadow: "" },
                        },
                        [
                          _c(
                            "label",
                            { staticClass: "text-primary text-uppercase" },
                            [
                              _vm._v(
                                "\n                    Produtos Cadastrados\n                  "
                              ),
                            ]
                          ),
                          !_vm.loading
                            ? _c("p", { staticClass: "siraf_stats" }, [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm.activeIndicators.products_count
                                    ) +
                                    "\n                  "
                                ),
                              ])
                            : _c(
                                "p",
                                [
                                  _c("b-icon", {
                                    staticClass: "bi-spin",
                                    attrs: {
                                      variant: "muted",
                                      icon: "arrow-clockwise",
                                      animation: "throb",
                                      "font-scale": "3",
                                    },
                                  }),
                                ],
                                1
                              ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { staticClass: "section-shaped my-0" }, [
      _c("div", {
        staticClass: "shape shape-style-1 shape-primary shape-skew",
      }),
      _c("div", { staticClass: "container shape-container d-flex" }, [
        _c("div", { staticClass: "col px-0" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-12" }, [
              _c("h1", { staticClass: "display-3 text-white text-center" }, [
                _vm._v(
                  "\n                Sistema de Informação Regional da Agricultura Familiar no\n                Nordeste\n              "
                ),
              ]),
              _c("p", { staticClass: "lead text-white text-center" }, [
                _vm._v(
                  "\n                Ampliação da participação de agricultores familiares no\n                processo de compras governamentais e disponibilização de seus\n                produtos a outros mercados.\n              "
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }